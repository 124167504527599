<template>
  <div class="banner-footer-home">
    <v-container fluid>
      <v-row class="justify-center">
        <v-col cols="12" md="8" lg="6">
          <v-sheet
            color="rgba(255, 255, 255, 0.85)"
            rounded="lg"
            elevation="4"
            class="d-flex align-center justify-center flex-column py-8 px-4"
          >
            <h2 class="text-primary text-h5 font-weight-bold text-center mb-4">
              Mari Jadi Bagian Dari Kegiatan Positif yang Berdampak Pada
              Masyarakat
            </h2>

            <v-btn
              color="primary"
              size="large"
              class="rounded-xl text-capitalize font-weight-bold px-6"
              to="/auth?type=signup"
              prepend-icon="mdi-account-plus"
              elevation="2"
            >
              Bergabung Sekarang
            </v-btn>

            <v-btn
              variant="text"
              class="mt-2"
              to="/activities"
              prepend-icon="mdi-calendar-check"
            >
              Lihat Kegiatan
            </v-btn>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.banner-footer-home {
  background-color: #f5f5f5; /* Fallback color */
  background-image: url("/assets/images/banner-footer.jpeg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  padding: 4rem 0;
  isolation: isolate;
}

.banner-footer-home::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3));
  z-index: -1;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .banner-footer-home {
    background-attachment: scroll; /* Better performance on mobile */
    padding: 2rem 0;
  }
}

/* Button hover animation */
.v-btn {
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.v-btn:not(.v-btn--variant-text):hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
</style>
